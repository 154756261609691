import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCodeLabel} from "@core/utils/filter";

export default function serviceorderUseList() {
    // Use toast
    const toast = useToast()

    const refListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: 'city', label: '城市'},
        {key: 'product_name', label: '商品名称'},
        {key: 'product_code', label: '69码'},
        {key: 'qty', label: '实时库存', sortable: true},
        {key: 'cost_qty', label: "近10天日均销量", sortable: true},
        {key: 'sales_time', label: '近10天售卖天数', sortable: true},
        {key: 'average_price', label: '近10天平均售价', sortable: true},
        {key: 'average_cost', label: '近10天平均成本', sortable: true},
        {key: 'average_tax_rate', label: '平均毛利率', sortable: true},
    ]

    const start = ref(1)
    const limit = ref(10)
    const listTotals = ref(0)
    const limitOptions = [10, 25, 50, 100]
    const searchKey = ref('')
    const orderBy = ref('s.product_id')
    const isSortDirDesc = ref(true)
    const condition = ref({
        day: 10,
    })
    const allStockQty = ref(0)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey], () => {
        refetchData()
    })

    const searchList = (ctx, callback) => {
        if (condition.value.day != ''&&condition.value.day != undefined) {
            tableColumns[4].label = "近" + condition.value.day + "天日均销量"
            tableColumns[5].label = "近" + condition.value.day + "天售卖次数"
            tableColumns[6].label = "近" + condition.value.day + "天平均售价"
            tableColumns[7].label = "近" + condition.value.day + "天平均成本"
        }else {
            tableColumns[4].label = "近10天日均销量"
            tableColumns[5].label = "近10天售卖次数"
            tableColumns[6].label = "近10天平均售价"
            tableColumns[7].label = "近10天平均成本"
        }
        const city = []
        const warehouse = []
        if (condition.value.chooseArea != null) {
            const cityArray = condition.value.chooseArea
            for (let i = 0; i < cityArray.length; i++) {
                city.push(cityArray[i].id)
            }
        }
        if (condition.value.chooseWarehouse != null) {
            const warehouseArray = condition.value.chooseWarehouse
            for (let i = 0; i < warehouseArray.length; i++) {
                warehouse.push(warehouseArray[i].id)
            }
        }
        store
            .dispatch('purchaseorder/getSalesReport', {
                search_key: searchKey.value,
                start: start.value,
                limit: limit.value,
                order_by: 's.product_id',
                order_desc: isSortDirDesc.value === true ? 'desc' : '',
                city: condition.value.chooseArea == null ? null : city.join(","),
                warehouse: condition.value.chooseWarehouse == null ? null : warehouse.join(","),
                productName: condition.value.product_name,
                code: condition.value.product_code,
                supplier: condition.value.company_id,
                day: condition.value.day,
                user: condition.value.userId,
                orderBys: orderBy.value,
                orderDescs: isSortDirDesc.value === true ? 'desc' : '',
            })
            .then(response => {
                const data = response.data.data
                const list = data.ext.list
                listTotals.value = data.ext.totals
                allStockQty.value = data.ext.allStockQty
                console.log(data)
                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        condition,
        allStockQty,
        // Extra Filters
    }
}
