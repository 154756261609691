<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <div class="demo-spacing-0">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body" >
                  <span><strong>总库存量:{{allStockQty}}</strong> </span>
                </div>
              </b-alert>
            </div>

          </b-col>

          <!-- Search -->
          <!-- Search -->
          <b-col
              cols="12"
              md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>

        </b-row>
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="城市:"
                    label-for="city"
                    label-size="sm"
                >
                  <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="title"
                      :options="area"
                      v-model="condition.chooseArea"
                      @input="changeSelect($event,'area')"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仓库:"
                    label-for="warehouse"
                    label-size="sm"
                >
                  <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="title"
                      :options="warehouse"
                      v-model="condition.chooseWarehouse"
                      class="select-size-sm"
                  />
                </b-form-group>

              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方商品名称:"
                    label-for="product_name"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.product_name"
                      name="product_name"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="请输入商品名称"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="code"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.product_code"
                      name="product_name"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="请输入商品69码"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-input-group
                    label-cols="3"
                    label-cols-lg="3"
                    prepend="近期销量"
                    label-for="code"
                    append="天"
                    size="sm"
                >
                  <b-form-input
                      v-model="condition.day"
                      class="d-inline-block"
                      placeholder="请输入查询天数"
                      type="number"
                      size="sm"
                  />
                </b-input-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="采购人"
                    type="input"
                    v-on:change="fromChildren($event,['userName','userId'])"
                    :params="['userName','userId']"
                    :value="condition.userName"
                    modalName="销售员"
                    placeholder="点击选择采购人"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="供应商"
                    type="input"
                    v-on:change="fromChildren($event,['companyName','company_id'])"
                    :params="['companyName','company_id']"
                    :value="condition.companyName"
                    modalName="供应商"
                    placeholder="点击选择供应商"
                >
                </xy-input-button>
              </b-col>


              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(city)="data">
          {{getCodeLabel("area",data.item.city_id)}}
        </template>

        <template #cell(qty)="data">
          <b-link @click="showModal('whModal',data.item)">
            {{data.item.qty}}
          </b-link>
        </template>

        <template #cell(cost_qty)="data">
          <b-link @click="showModal('cqModal',data.item)">
            {{data.item.cost_qty}}
          </b-link>
        </template>

        <template #cell(sales_time)="data">
          {{data.item.sales_time}}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

      </div>
    </b-card>

    <b-modal
        id="whModal"
        ok-only
        ok-title="关闭"
        centered
        size="lg"
        title="库存列表"
        ref="whModal"
    >
      <b-table
          ref="refListTableWH"
          class="position-relative"
          :items="whList"
          responsive
          :fields="whTable"
          primary-key="id"
          show-empty
          empty-text="未找到记录"
      >
        <template #cell(warehouse_id)="data">
          {{getCodeLabel("warehouse",data.item.warehouse_id)}}
        </template>

        <template #cell(supplier)="data">
          {{getCodeLabel("company",data.item.ext.supplier)}}
        </template>

        <template #cell(user)="data">
          {{getCodeLabel("user",data.item.ext.user)}}
        </template>

      </b-table>

    </b-modal>

    <b-modal
        id="cqModal"
        ok-only
        ok-title="关闭"
        centered
        size="lg"
        title="销量列表"
        ref="cqModal"
    >
      <b-table
          ref="refListTableCQ"
          class="position-relative"
          :items="cqList"
          responsive
          :fields="cqTable"
          primary-key="id"
          show-empty
          empty-text="未找到记录"
      >
        <template #cell(date)="data">
          {{toDate(data.item.ext.date)}}
        </template>

        <template #cell(salesVolume)="data">
          {{data.item.ext.salesVolume}}
        </template>

      </b-table>

    </b-modal>


  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BInputGroup,BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, watch} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import CompanySelect from "../company/CompanySelect"
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import Ripple from "vue-ripple-directive";
import WarehouseList from "@/views/apps/warehouse/warehouse-modal/WarehouseList";
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import {useToast} from "vue-toastification/composition";
import StoreListSelect from '@/views/apps/store/StoreListSelect'
import serviceorder from "@/router/routes/apps/serviceorder";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import ProductList from "@/views/apps/product/product-datasales/ProductSelect";
import {getUserData} from "@/auth/utils";
import XyInputButton from "@/views/components/xy/XyInputButton";
import purchaseorderStore from "@/views/apps/purchaseorder/purchaseorderStore";
import salesReportUseList from "./salesReportUseList";
import areaStore from "@/views/apps/area/areaStore";


export default {
  components: {
    XyInputButton,
    ProductList,
    WarehouseList,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
    CompanySelect,
    UserSelect,
    StoreListSelect,
    serviceorder,
    ToastificationContent,
    AttachmentUpload,
    warehouseStore,
    useToast,
  },
  directives: {
    Ripple,
  },
  methods: {
    resetCondition() {
      this.condition = {}
      this.refetchData()
    },
    showSingleModal(modalName, data) {
      if (modalName === '渠道方' || modalName === '平台') {
        this.companyType = 'ChannelCustomer'
      } else if (modalName === '主体') {
        this.companyType = 'Group'
      } else if (modalName === '店铺') {
        this.companyType = 'Store'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()
      if (modalName === '商品') {
        this.outProductId = data.out_product_id
        this.related = data.is_related
        this.create = data.is_createorder
      }
    },
  },
  data() {
    return {
      advanced_search_modal: false,
      advanced_search_modal_excel: false,
      modalName: "",
      area:[],
      warehouse:[],
      user: {},

      whTable :[
        {key: 'warehouse_id', label: '仓库'},
        {key: 'supplier', label: '供应商'},
        {key: 'user', label: '采购人'},
        {key: 'qty', label: '库存'},
      ],
      whList:[],

      cqList:[],
      cqTable:[
          {key:'date',label:'日期'},
          {key:'salesVolume',label:'销量'},
      ],
    }

  },

  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)
    if (!store.hasModule('area')) store.registerModule('area', areaStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)



    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseorder')) store.unregisterModule('purchaseorder')
      if (store.hasModule('area')) store.unregisterModule('area')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')

    })

    const searchByCondition = function () {
      refetchData()
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const getArea = function () {
      store
          .dispatch('area/getAreaInStock')
          .then(res => {
            const list = res.data.data
            for (let i = 0; i < list.length; i++) {
              this.area.push({"title":list[i].area_name,"id":list[i].area_id})
            }
          })

      store
          .dispatch('warehouse/list')
          .then(res => {
            const list = res.data.data.list
            for (let i = 0; i < list.length; i++) {
              this.warehouse.push({"title":list[i].warehouse_name,"id":list[i].warehouse_id})
            }
          })

    }

    const changeSelect = function (event,params) {
      if (params=='area'){
        this.warehouse.length=0
       const area = this.condition.chooseArea
       const cityIds = []
        for (let i = 0; i < area.length; i++) {
          cityIds.push(area[i].id)
        }
        store
            .dispatch('warehouse/list', {
              cityIds:cityIds.join(","),
            })
            .then(res => {
              const list = res.data.data.list
              for (let i = 0; i < list.length; i++) {
                this.warehouse.push({"title":list[i].warehouse_name,"id":list[i].warehouse_id})
              }
            })
      }

    }

    const showModal = function (val,params) {
      if (val==="whModal"){
        store
            .dispatch('purchaseorder/getWHStock', {
              productId:params.product_id,
              city:params.city_id,
            })
            .then(res => {
              if (res.data.code==0){
                this.whList = res.data.data
              }else {
                toast.error(res.data.data)
              }
            })
        this.$refs['whModal'].show()
      }

      if (val==="cqModal"){
        this.cqList = params.ext.salesVolumeList
        this.$refs['cqModal'].show()
      }

    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      allStockQty,
      // UI
    } = salesReportUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      advanced_search,
      searchByCondition,
      toTime,
      toDate,
      fromChildren,
      getArea,
      changeSelect,
      showModal,

      condition,
      allStockQty,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    this.getArea()

  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
